import React from "react"
import Layout from "../../components/App/Layout"
import SEO from "../../components/App/SEO"
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import { Link } from "gatsby"
import { graphql } from "gatsby"
const img = "/img/desktop/Bluemail-Linux-Application-Dark-Mode-a.webp"

const linuxPage = ({ data }) => (
  <Layout env={data.site.siteMetadata.env}>
    <Navbar />
    <SEO
      postTitle="Desktop Linux | BlueMail App"
      postDescription="BlueMail for Linux is here"
      postURL="desktop/linux"
    />
    <div className="container pt-120 pb-100">
      <div className="row">
        <div className="col-12 center-text">
          <h2 className="mb-30">
            The Most Powerful Email App Has Now Arrived on Linux
          </h2>
          <img
            src={img}
            style={{ maxWidth: "950px", width: "100%" }}
            className="mb-30"
            alt="BlueMail Linux"
          />

          <div
            style={{
              justifyContent: "center",
              display: "flex",
              flexWrap: "wrap",
            }}
          >

          {/* 
            <div>
              <a
                href="https://download.bluemail.me/BlueMail/deb/BlueMail.deb"
                target="_blank"
                rel="noreferrer"
                style={{
                  color: "#000000",
                  border: "1px solid #cdcdcd",
                  background: "#fff",
                  fontSize: "1.1rem",
                  display: "flex",
                  fontWeight: "400",
                  maxWidth: "180px",
                  padding: "15px 12px",
                  minWidth: "180px",
                  alignItems: "center",
                  margin: "10px",
                }}
              >
                <img
                  src={`/img/home/Openlogo-debianV2.svg`}
                  alt="Openlogo-debianV2"
                  style={{ marginRight: "9px" }}
                />
                <div style={{ fontSize: "1rem" }}>Download .deb</div>
              </a>
            </div>
          */}

            <div>
              <a
                href="https://download.bluemail.me/BlueMail/rpm/BlueMail.rpm"
                target="_blank"
                rel="noreferrer"
                style={{
                  color: "#000000",
                  border: "1px solid #cdcdcd",
                  background: "#fff",
                  fontSize: "1.1rem",
                  display: "flex",
                  fontWeight: "400",
                  maxWidth: "180px",
                  padding: "15px 12px",
                  minWidth: "180px",
                  alignItems: "center",
                  margin: "10px",
                }}
              >
                <img
                  src={`/img/home/RedHat.svg`}
                  alt="RedHat"
                  style={{ marginRight: "9px" }}
                />
                <div style={{ fontSize: "1rem" }}>Download .rpm</div>
              </a>
            </div>
            <div>
              <a
                href="https://snapcraft.io/bluemail"
                target="_blank"
                rel="noreferrer"
                style={{
                  color: "#fff",
                  border: "1px solid #cdcdcd",
                  background: "#252525",
                  fontSize: "1.1rem",
                  display: "flex",
                  width: "180px",
                  padding: "9px 11px",
                  textAlign: "left",
                  lineHeight: "1",
                  minWidth: "180px",
                  margin: "10px",
                }}
              >
                <img
                  src="https://avatars0.githubusercontent.com/u/19532717?s=400&v=4"
                  alt="githubusercontent"
                  style={{
                    marginRight: "9px",
                    maxWidth: "36px",
                    maxHeight: "36px",
                    alignSelf: "center",
                  }}
                />
                <div style={{ alignSelf: "center" }}>
                  <div style={{ fontSize: "0.8rem" }}>Get it from the</div>
                  <div style={{ fontWeight: "400", fontSize: "1.2rem" }}>
                    Snap Store
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="mt-30">
            <p>
              Not on Linux?
              <br />
              Download for{" "}
              <Link to="/desktop/windows/">
                <b style={{ color: "#1F6BF1" }}>Windows</b>
              </Link>{" "}
              or{" "}
              <Link to="/desktop/mac/">
                <b style={{ color: "#1F6BF1" }}>Mac</b>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </Layout>
)

export default linuxPage

export const query = graphql`
  query LinuxPageQuery {
    site {
      siteMetadata {
        title
        env
      }
    }
  }
`
